
import React from "react"


function RightArrow({ }) {

  return (
    <svg
      className="scale-75 fill-current stroke-current md:scale-100"
      width="19"
      height="19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="presentation">
      <path d="m11.965 11.278-4.51 4.51-.354.354.353.354 1.414 1.414.354.353.354-.353 7.778-7.778.353-.354-.353-.354-7.778-7.778-.354-.353-.354.353L7.454 3.06l-.353.354.353.354 4.51 4.51H.5v3h11.465Z" />
    </svg>
  )

}


export default RightArrow
