
import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import SocialIcons from "../components/social-icons"
import CourseCard from "../components/course-card"
import DarkSection from "../components/dark-section"
import Icon from "../components/icon"

import RightArrow from "../components/right-arrow"

import DockerLogo from "../components/icons/tool-docker.svg"
import KubernetesLogo from "../components/icons/tool-kubernetes.svg"
import AWSLogo from "../components/icons/tool-aws.svg"
import AnsibleLogo from "../components/icons/tool-ansible.svg"
import TerraformLogo from "../components/icons/tool-terraform.svg"
import GitLogo from "../components/icons/tool-git.svg"


function IndexPage({ location, data }) {

  const courses = data.allCoursesYaml.nodes

  return (
    <Layout location={location}>

      <Seo 
        title="DevOps and Software Automation Training to Become a DevOps Engineer" 
        metaKeywords={["devops", "software automation", "devops engineer", "automation io", "automate.io", "io", "apps", "teams", "data", "use"]} 
        metaDescription="Extreme automation is about enabling your business to move faster & deliver reliable software, as well as offering expertise in implementing Devops initiatives."
      />

      {/* Andrey Adamovich */}
      <div className="grid grid-flow-row fullbleed">
        <div className="z-10 grid-area-full">
          <div className="container grid md:grid-cols-2 md:gap-12 lg:gap-15.5">
            <div className="md:relative md:col-start-2 md:row-span-2 md:row-start-1">
              <picture>
                <source srcSet="/images/andrey-adamovich.jpg, /images/andrey-adamovich@2x.jpg 2x" media="(min-width: 720px)" />
                <img className="object-cover w-full h-auto md:absolute md:inset-0" src="/images/sm/andrey-adamovich.jpg" alt="Andrey Adamovich" srcSet="/images/sm/andrey-adamovich@2x.jpg 2x" width="343" height="373" />
              </picture>
            </div>
            <div className="pb-10 md:col-start-1 md:row-span-2 md:row-start-1">
              <div>
                <h1 className="mt-10 md:mt-3">Andrey Adamovich</h1>
                <div className="flex items-center justify-between mt-4 gap-8x max-w-89 md:mt-6">
                  <SocialIcons />
                </div>
                <div className="space-y-8 mt-11 md:mt-14">
                  <p>
                    Andrey is a software craftsman with many years of experience in different
                    lifecycle phases of software creation. He is passionate about defining good
                    development practices, documenting and presenting architecture, reuse of code
                    and design patterns, profiling and analysis of application performance as well
                    as extreme automation of development and operations activities.
                  </p>
                  <p>
                    At the moment, Andrey works as a free-lance DevOps consultant offering his
                    expertise in implementing <strong>DevOps initiatives</strong>, selecting
                    automation tooling, switching to infrastructure-as-code and immutable
                    infrastructure and constructing software delivery pipelines.
                  </p>
                </div>
                <div className="flex items-center justify-between w-full gap-2 mt-8 md:mt-9">
                  <img className="w-8 h-auto" src={AWSLogo} alt="AWS" width="43" height="35" loading="lazy" />
                  <img className="w-8 h-auto" src={TerraformLogo} alt="Terraform" width="36" height="40" loading="lazy" />
                  <img className="w-8 h-auto" src={KubernetesLogo} alt="Kubernetes" width="45" height="44" loading="lazy" />
                  <img className="w-8 h-auto" src={DockerLogo} alt="Docker" width="47" height="34" loading="lazy" />
                  <img className="w-8 h-auto" src={AnsibleLogo} alt="Ansible" width="43" height="43" loading="lazy" />
                  <img className="hidden lg:block" src={GitLogo} alt="Git" width="41" height="40" loading="lazy" />
                  <span className="flex-none text-xs md:text-sm">and more...</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row-start-1 col-span-full"></div>
        <div className="row-start-2 pb-6 bg-gray-100 col-span-full md:pb-11 lg:pb-20">
          <div className="container grid lg:grid-cols-2 lg:gap-15.5">
            <div className="col-start-1 row-start-1 mt-12.5 space-y-8">
              <p>
                Another Andrey's passion is teaching software automation practices and tooling. His
                &nbsp;<strong>DevOps MasterClass (eXtreme Automation)</strong> course has been delivered more
                than 100 times in various locations in Europe: Austria, Sweden, Denmark, UK, Romania,
                Estonia, Latvia.
              </p>
              <p>
                Andrey is a <strong>frequent <Link to="/speaking/">speaker</Link></strong> at international conferences and local
                community gatherings.
              </p>
            </div>
          </div>
        </div>
      </div>


      {/* Training course list */}
      <section className="mt-8 lg:mt-18">

        <header className="container">
          <h2 className="text-center gap-x-4">
            <Icon id="graduation-hat" size={45} />Customized Training Classes
          </h2>
          <p className="mt-5 text-center">
            Select courses from the list or let me design a training track tailored for your company.
          </p>
        </header>

        <div className="grid gap-5 mt-11 md:mt-14 md:grid-cols-2 lg:grid-cols-4 lg:gap-6">

          <article className="grid h-72 content-center justify-items-center bg-sky-900 px-4 pt-4 pb-3.5 text-center md:col-span-2 md:col-start-1 md:px-24">

            <Icon id="customized-light" size={45} />

            <h3 className="mt-5 leading-tight text-white">
              Customized Training Track
            </h3>
            <p className="px-8 mt-4 text-xs text-white/60">
              I can design training workshops, hackathons, mob programming sessions especially for your company's unique needs.
            </p>
          </article>

          {courses.slice(0, 5).map((course, courseIndex) => {
            return (
              <CourseCard
                inGrid={true}
                key={courseIndex}
                title={course.title}
                description={course.abstract}
                duration={course.duration}
                difficulty={course.difficulty}
                slug={course.slug}
                icon={course.icon}
                type={course.type}
                top={course.top}
              />)
          })}

          <Link to="/courses/" className="duration-200 hover:-translate-x-1 hover:-translate-y-1 hover:drop-shadow-lg">
            <div className="grid content-center px-4 py-4 text-center h-72 justify-items-center bg-rose-400 group">
              <div>
                <h3 className="mt-5 text-[64px] leading-tight text-rose-300">{courses.length}</h3>
                <p className="mt-4 text-base font-bold text-white">See more classes</p>
              </div>
              <span className="mt-14 flex items-center gap-2.5 text-base font-bold uppercase tracking-wide text-white group-hover:underline">
                Check them all
                <RightArrow />
              </span>
            </div>
          </Link>

        </div>

      </section>


      {/* Optimize your organization */}
      <DarkSection
        title="Optimize Your Organization"
        icon="optimize-org-light"
        cover="/images/organization-02@2x.jpg"
      >
        <p className="text-xl text-white/60">
          I can analyze technological stack and team topologies of your company,
          help you make sense of the sociotechnical landscape of your software delivery,
          provide insights based on my extensive experience, and
          prepare a <strong>report</strong> with carefully crafted short-term and
          long-term strategical <strong>improvement plan</strong>.
        </p>
      </DarkSection>


      {/* Automate your organization */}
      <DarkSection
        title="Automate Your Organization"
        layout="right"
        icon="automation-light"
        cover="/images/organization-01@2x.jpg"
      >
        <p className="text-xl text-white/60">
          I can help your company design and implement <strong>automation</strong> procedures
          for important software delivery and infrastructure management processes.
        </p>
      </DarkSection>


      {/* Client list */}
      <section className="pb-32 mt-10 lg:mt-20">

        <h2 className="text-center">Clients include</h2>

        <div className="flex flex-wrap items-center justify-center gap-4 mt-8 md:gap-8">
          <picture>
            <source srcSet="/images/clients/oracle-logo.png, /images/clients/oracle-logo@2x.png 2x" media="(min-width: 720px)" />
            <img className="lg:w-full" src="/images/clients/sm/oracle-logo.png" alt="Oracle" srcSet="/images/clients/sm/oracle-logo@2x.png 2x" width="87" height="12" loading="lazy" />
          </picture>
          <picture>
            <source srcSet="/images/clients/orange-logo.png, /images/clients/orange-logo@2x.png 2x" media="(min-width: 720px)" />
            <img className="lg:w-full" src="/images/clients/sm/orange-logo.png" alt="Orange" srcSet="/images/clients/sm/orange-logo@2x.png 2x" width="71" height="20" loading="lazy" />
          </picture>
          <picture>
            <source srcSet="/images/clients/lego-logo.png, /images/clients/lego-logo@2x.png 2x" media="(min-width: 720px)" />
            <img className="lg:w-full" src="/images/clients/sm/lego-logo.png" alt="Lego" srcSet="/images/clients/sm/lego-logo@2x.png 2x" width="49" height="22" loading="lazy" />
          </picture>
          <picture>
            <source srcSet="/images/clients/accenture.png, /images/clients/accenture@2x.png 2x" media="(min-width: 720px)" />
            <img className="lg:w-full" src="/images/clients/sm/accenture.png" alt="Accenture" srcSet="/images/clients/sm/accenture@2x.png 2x" width="75" height="21" loading="lazy" />
          </picture>
          <picture>
            <source srcSet="/images/clients/airbaltic-logo.png, /images/clients/airbaltic-logo@2x.png 2x" media="(min-width: 720px)" />
            <img className="lg:w-full" src="/images/clients/sm/airbaltic-logo.png" alt="airBaltic" srcSet="/images/clients/sm/airbaltic-logo@2x.png 2x" width="59" height="14" loading="lazy" />
          </picture>
          <picture>
            <source srcSet="/images/clients/citadele.png, /images/clients/citadele@2x.png 2x" media="(min-width: 720px)" />
            <img className="lg:w-full" src="/images/clients/sm/citadele.png" alt="Citadele" srcSet="/images/clients/sm/citadele@2x.png 2x" width="61" height="29" loading="lazy" />
          </picture>
          <picture>
            <source srcSet="/images/clients/netcompany.png, /images/clients/netcompany@2x.png 2x" media="(min-width: 720px)" />
            <img className="lg:w-full" src="/images/clients/sm/netcompany.png" alt="NetCompany" srcSet="/images/clients/sm/netcompany@2x.png 2x" width="97" height="17" loading="lazy" />
          </picture>
          <picture>
            <source srcSet="/images/clients/amido.png, /images/clients/amido@2x.png 2x" media="(min-width: 720px)" />
            <img className="lg:w-full" src="/images/clients/sm/amido.png" alt="Amido" srcSet="/images/clients/sm/amido@2x.png 2x" width="55" height="30" loading="lazy" />
          </picture>
          <picture>
            <source srcSet="/images/clients/intrum-justitia.png, /images/clients/intrum-justitia@2x.png 2x" media="(min-width: 720px)" />
            <img className="lg:w-full" src="/images/clients/sm/intrum-justitia.png" alt="Intrum" srcSet="/images/clients/sm/intrum-justitia@2x.png 2x" width="131" height="24" loading="lazy" />
          </picture>
          <picture>
            <source srcSet="/images/clients/imt.png, /images/clients/imt@2x.png 2x" media="(min-width: 720px)" />
            <img className="lg:w-full" src="/images/clients/sm/imt.png" alt="LMT" srcSet="/images/clients/sm/imt@2x.png 2x" width="64" height="20" loading="lazy" />
          </picture>
          <picture>
            <source srcSet="/images/clients/dxc-technology-logo.png, /images/clients/dxc-technology-logo@2x.png 2x" media="(min-width: 720px)" />
            <img className="lg:w-full" src="/images/clients/sm/dxc-technology-logo.png" alt="DXC" srcSet="/images/clients/sm/dxc-technology-logo@2x.png 2x" width="119" height="21" loading="lazy" />
          </picture>
          <picture>
            <source srcSet="/images/clients/skat-dk.png, /images/clients/skat-dk@2x.png 2x" media="(min-width: 720px)" />
            <img className="lg:w-full" src="/images/clients/sm/skat-dk.png" alt="skat.dk" srcSet="/images/clients/sm/skat-dk@2x.png 2x" width="74" height="17" loading="lazy" />
          </picture>
        </div>
      </section>


    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allCoursesYaml(
      sort: { fields: [priority], order: ASC }
      filter: { hidden: { ne: true } }
    ) {
      nodes {
        id
        title
        abstract
        description
        difficulty
        duration
        icon
        slug
        type
        top
        meta {
          keywords
          title
          description
        }
      }
    }
  }
`
