
import React from "react"

import PropTypes from "prop-types"

import Icon from "./icon"


function Image({ cover, layout }) {
  return (
    <div
      className={"bg-cover bg-center w-full row-start-1 col-span-3 " + (layout == "left" ? "col-start-1" : "col-start-7")}
      style={{ backgroundImage: 'url("' + cover + '")' }}>
    </div>
  )
}

function Text({ icon, title, description, layout, children }) {
  return (
    <div className={"pt-16 pb-20 xl:py-34 row-start-1 col-span-4 " + (layout == "left" ? "col-start-5" : "col-start-2")}>
      <h2 className="flex flex-col items-center gap-x-2.5 text-3xl whitespace-nowrap capitalize text-white md:flex-row md:items-center">
        <Icon id={icon} size={32} /> {title}
      </h2>
      <div className="mt-7">
        {description ? <p className="text-2xl text-white/60">{description}</p> : <></>}
        {children}
      </div>
    </div>
  )
}

function DarkSection({ id, title, description, icon, cover, layout, children }) {
  return (
    <section className="mt-10 fullbleed bg-sky-900 lg:mt-34 lg:overflow-hidden">
      {id ? <a id={id}></a> : <></>}
      <div className="px-0 grid grid-cols-9">
        {layout === "left" ?
          <>
            <Image cover={cover} layout={layout} />
            <Text icon={icon} title={title} description={description} layout={layout}>
              {children}
            </Text>
          </> :
          <>
            <Text icon={icon} title={title} description={description} layout={layout}>
              {children}
            </Text>
            <Image cover={cover} layout={layout} />
          </>
        }
      </div>
    </section>
  )
}

DarkSection.defaultProps = {
  layout: "left",
  id: null
}

DarkSection.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  icon: PropTypes.string,
  cover: PropTypes.node.isRequired,
  layout: PropTypes.oneOf(['left', 'right']),
  children: PropTypes.node,
}

export default DarkSection
