
import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"

import Icon from "./icon"

function CourseCard({ title, slug, description, type, difficulty, duration, icon, top, backgroundColor, textColor, inGrid }) {

  const data = useStaticQuery(graphql` 
    query {
      allFile(filter: {
        sourceInstanceName: {eq: "courses"}
        extension: {eq: "svg"}
        relativeDirectory: {eq: "images/icons"}
      }
      ) {
        edges {
          node {
            id
            base
            publicURL
          }
        }
      }
    }
  `)

  let imageFile = undefined
  data.allFile.edges.forEach(edge => {
    if (edge.node.base == icon + ".svg") {
      imageFile = edge.node.publicURL
    }
  })

  return (
    <Link to={slug} className="duration-200 hover:-translate-x-1 hover:-translate-y-1 hover:drop-shadow-lg">
      <div className={(backgroundColor ? backgroundColor : "bg-rose-400") + (inGrid ? "" : " md:w-68") + " grid h-72 content-between group px-4 pt-4 pb-3.5 text-center"}>
        <div className={top ? "flex items-center justify-between" : "flex items-center justify-end"}>
          {top ?
            <span
              className={(textColor ? textColor : "text-white") + " px-3 py-1 font-bold text-xxxs tracking-widest bg-white/10 uppercase"}>
              <Icon id="gem" size={12} className="inline-block mr-1 -mt-1" />TOP
            </span> : <></>
          }
          <span className={(textColor ? textColor : "text-white") + " px-3 py-1 font-bold text-xxxs tracking-widest bg-white/10 uppercase"}>{type}</span>
        </div>
        <div className="grid content-center justify-items-center">
          <img src={imageFile} alt={title} className="opacity-50" />
          <h3 className={(textColor ? textColor : "text-white") + " mt-3 leading-tight group-hover:underline " + (description ? "" : "mx-2 text-2xl")}>{title}</h3>
          {description ?
            <p className="px-8 mt-4 text-xxs text-white md:px-0">{description}</p>
            : <></>
          }
        </div>
        <div className="flex items-center justify-between">
          <span className={(textColor ? textColor : "text-white") + " text-xs font-bold tracking-widest uppercase"}>{difficulty}</span>
          <span className={(textColor ? textColor : "text-white") + " text-xs font-bold tracking-widest uppercase"}>{duration}</span>
        </div>
      </div>
    </Link>
  )

}

CourseCard.defaultProps = {
  top: false,
  inGrid: false,
  backgroundColor: "bg-rose-400",
  textColor: "text-white"
}

CourseCard.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  description: PropTypes.string,
  type: PropTypes.string,
  difficulty: PropTypes.string,
  duration: PropTypes.string,
  icon: PropTypes.string.isRequired,
  top: PropTypes.bool,
  inGrid: PropTypes.bool,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
}

export default CourseCard
